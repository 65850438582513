import { Card, Container, Typography } from "@mui/material";
import * as React from "react";

const reactLogo = require("./../assets/img/react_logo.svg");
import "./../assets/scss/App.scss";
import GlobalService from "../services/global";

function Home() {
	const [totalUsers, setTotalUsers] = React.useState(0);
	const [stats, setStats] = React.useState({
		pendingWithdrawals: 0,
		newStripeDisputes: 0,
		pendingQuestions: 0,
	});

	const getTotalUsers = async () => {
		console.log("here");
		const data = await GlobalService.httpGet(
			"api/admin/dashboard/user/registered/count"
		);
		if (!data.isError) setTotalUsers(data.users);
	};

	const getStats = async () => {
		const data = await GlobalService.httpGet("api/admin/dashboard/stats");
		if (!data.isError)
			setStats({
				pendingWithdrawals: data.pendingWithdrawals,
				pendingQuestions: data.pendingQuestions,
				newStripeDisputes: data.newStripeDisputes,
			});
	};

	React.useEffect(() => {
		getTotalUsers() //todo: call this function every 5 seconds
		getStats();
	}, []);

	return (
		<Container sx={{ width: "100%"}}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					marginTop: 80,
					alignSelf: "center"
				}}
			>
				<Typography
					style={{
						fontWeight: "400",
						// color: "#fbc02d"
					}}
					variant="h1"
				>
					{totalUsers}
				</Typography>
				<Typography>Total Users</Typography>
			</div>
			<div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: 80}} >
				<Card style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", paddingTop: 20, paddingBottom: 20, marginRight: 20}} >
					<Typography variant="h2" style={{ fontWeight: "400", color: stats.pendingWithdrawals==0?"#4CAF50":"#F44336"}}>{stats.pendingWithdrawals}</Typography>
					<Typography variant="body2" style={{color: "#2196F3"}}>Pending Withdrawals</Typography>
				</Card>
				<Card style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", paddingTop: 20, paddingBottom: 20, marginRight: 20}} >
					<Typography variant="h2" style={{ fontWeight: "400", color: stats.pendingQuestions==0?"#4CAF50":"#F44336"}}>{stats.pendingQuestions}</Typography>
					<Typography variant="body2" style={{color: "#2196F3"}}>Pending Queries</Typography>
				</Card>
				<Card style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", paddingTop: 20, paddingBottom: 20}} >
					<Typography variant="h2" style={{ fontWeight: "400", color: stats.newStripeDisputes==0?"#4CAF50":"#F44336"}}>{stats.newStripeDisputes}</Typography>
					<Typography variant="body2" style={{color: "#2196F3"}}>New Stripe Disputes</Typography>
				</Card>
			</div>
		</Container>
	);
}

export default Home;
