import * as React from "react";
import { useGoogleLogin } from 'react-google-login';
import { 
  Container, Paper, Typography, Button,
  Alert,
  LinearProgress
 } from '@mui/material';
import theme from '../assets/theme';
import AuthService from '../services/auth';
import { gapi } from "gapi-script";

import "./../assets/scss/App.scss";
import GlobalService from "../services/global";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

function Login() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    const start = () => {
      gapi.client.init({
        clientId:
          process.env.GOOGLE_CLIENT_ID,
        scope: "email",
      });
    };
    gapi.load("client:auth2", start);
  }, []);

  const onLoginSuccess = async (response) => {
    setLoading(true);
    setError("");
    try {
      console.debug("on login", response);
      let tokenId = response.tokenId;
      let name = response.profileObj.name;
      let data: any = await AuthService.signin(tokenId, name);
      GlobalService.setToken(data.token);
      // window.location.reload()
      setLoading(false);
    } catch (err) {
      console.error("error logging in", err);
      if (err.message) setError(err.message);
      else setError("Login failed, check logs");
      setLoading(false);
    }
  };

  const onLoginFail = (error) => {
    console.log("login failed", error);
    setError(JSON.stringify(error));
  };

  const { signIn } = useGoogleLogin({
    onSuccess: onLoginSuccess.bind(this),
    onFailure: onLoginFail.bind(this),
    clientId:
      process.env.GOOGLE_CLIENT_ID,
    scope: "https://www.googleapis.com/auth/userinfo.email",
  });

  return (
    <Container sx={{ width: "100%" }}>
      {GlobalService.token ? (
        <Navigate to="/" replace />
      ) : (
        <Paper
          sx={{
            display: "block",
            margin: "0 auto",
            mt: "30%",
            padding: "20px",
            width: "500px",
          }}
        >
          <Typography variant="h3" sx={{ textAlign: "center", mb: "30px" }}>
            Login
          </Typography>
          <Button
            className="centerbutton"
            variant="contained"
            onClick={signIn}
            color="secondary"
          >
            Login with Google
          </Button>
          {loading && <LinearProgress color="secondary" sx={{ mt: "20px" }} />}
          {error && (
            <Alert severity="error" sx={{ mt: "20px" }}>
              {error}
            </Alert>
          )}
        </Paper>
      )}
    </Container>
  );
}

export default Login;
