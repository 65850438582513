import GlobalService from './global'
import axios from 'axios'

export default class AuthService {

    static signin = async (googleTokenID, name) => {
		if(!GlobalService.envCheck()) return null;
        let url = `${GlobalService.ENDPOINT}/signin`
        try {
            let resp = await axios.post(url, {
                isApple: false,
                name ,
                idToken: googleTokenID,
                appleIdToken: null,
                otherDebugInfo: {},
            })
            console.log('signin', resp)
            return resp.data
        } catch(err) {
            console.error('signin err', err)
            throw err;
        }
    }
}