import React, { useEffect } from 'react'
import global from '../../services/global'
import { Container, Grid } from '@mui/material'
import ProductStats from './ProductStats'

export default function Products() {

  const [products, setProducts] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    (async() => {
      const res = await global.httpGet('api/admin/products/stats')
      setProducts(res.products);
      setLoading(false)
    })()
  },[])

  return (
    <Container sx={{ width: "100%" }}>
    <Grid container spacing={2} marginTop={2}>
      {
        loading ?
        [...Array(6)].map((_, index) => (
            <ProductStats product={[]} key={index} loading={true}/>
        )) :
        products.map((product) => {
          return (
            <ProductStats product={product} loading={false} key={product.find(p => p[0] == 'product_code')[1]}/>
          )
        })
      }
    </Grid>
    </Container>
  )
}
