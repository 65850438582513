import * as React from "react";
import { Container, Grow, Paper, Skeleton, TextField } from "@mui/material";
import GlobalService from "../services/global";
import { LoadingButton } from "@mui/lab";

const StripeTx = () => {
	const [searchInput, setSearchInput] = React.useState("");
	const [isSearchInpEmpty, setIsSearchInpEmpty] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [tx, setTx] = React.useState();

	const fetchTx = async () => {
		if (!searchInput) {
			return setIsSearchInpEmpty(true);
		} else {
			setIsLoading(true);
			setIsSearchInpEmpty(false);
			const data = await GlobalService.httpGet(
				`api/admin/transaction/stripe/${searchInput}`
			);
			console.debug("fetched stripe tx", data);

			if (data && !data.isError && data.stripeRes) {
				setTx(data.stripeRes);
			}

			setIsLoading(false);
		}
	};

	return (
		<Container
			sx={{ width: "100%", paddingTop: "30px", paddingBottom: "30px" }}
		>
			<div
				style={{ width: "100%", alignItems: "center", display: "flex" }}
			>
				<TextField
					id="outlined-basic"
					label="Stripe tx id"
					variant="outlined"
					sx={{ width: "40%", marginRight: "10px" }}
					name="searchInput"
					type="search"
					onChange={(e) => {
						setSearchInput(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							fetchTx();
						}
					}}
					error={isSearchInpEmpty}
				/>
				<LoadingButton
					variant="outlined"
					loading={isLoading}
					type="submit"
					onClick={fetchTx}
				>
					Submit
				</LoadingButton>
			</div>
			{isLoading ? (
				<Grow in={isLoading}>
					<Skeleton sx={{ marginTop: "30px", height: "300px" }} />
				</Grow>
			) : (
				<Paper
					sx={{
						display: "flex",
						flexDirection: "column",
						padding: "20px",
						marginTop: "30px",
						overflow: "auto"
					}}
					elevation={2}
				>
					<code
						style={{
							textAlign: "left",
							whiteSpace: "pre",
							wordSpacing: "normal",
							wordBreak: "normal",
							wordWrap: "normal",
							lineHeight: 1.5,
						}}
					>
						{JSON.stringify(tx, null, 2)}
					</code>
				</Paper>
			)}
		</Container>
	);
};

export default StripeTx;
