import React, { useEffect } from "react";
import {
    Button,
    TableCell,
    TableRow,
    TextField,
    IconButton,
    Collapse,
    LinearProgress,
    Autocomplete,
    CircularProgress,
  } from "@mui/material";
import format from "dateformat";
import InternalTx from "./InternalTx";
import { useState } from 'react'
import {KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import GlobalService from "../../services/global";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";



function Transaction(inputs: {tx: any, index: any, setRefresh:Function, tab: string}) {
    let { tx, index, setRefresh, tab } = inputs
    const [isExpanded, setExpanded] = useState(false)
    const [loading, setLoading] = useState(false)

    async function initiateWithdrawal() {
        if(tx.status !== 'pending') {
            alert("Transaction status should be pending");
            return;
        }
        const userRes = window.confirm(`Are you sure you want to initiate this withdrawal?`);
        if(!userRes) {
            return;
        }
        setLoading(true)
        const data = await GlobalService.httpPost(`api/admin/initiateWithdrawal`, {
            userid: tx.userid,
            masterTxId: tx.transid
        });
        if(data) {
            alert('Withdrawal initiated')
        }
        setLoading(false)
        setRefresh(true)
    }

    async function changeStatus(status: 'initiate'|'success' | 'reject', type: 'withdrawal'|'deposit') {
        if(status === "reject" && type === "withdrawal" && tx.status==="processing") {
            const userRes = window.confirm("Transaction status is processing, amount will be added to balance again. Do you want to continue?");
            if(!userRes) {
                return;
            }
        } else {
            const userRes = window.confirm(`Are you sure you want to ${status} this ${type}?`);
            if(!userRes) {
                return;
            }
        }
        setLoading(true)
        const data = await GlobalService.httpPost(`api/admin/${type}/${status}`, {
            userid: tx.userid,
            masterTxId: tx.transid
        });
        if(data && data.updated) {
            alert(type+ ' status updated to ' + status)
            setRefresh(true)
        }
        console.log("here");
        setLoading(false)
    }

    const StripeWithdraw = ({tx}) => {
        const [stripeOptLoading, setStripeOptLoading] = useState(false)
        const [open, setOpen] = useState(false);
        const [options, setOptions] = useState([]);
        const [selectedTxIds, setSelectedTxIds] = useState<Array<string>>([]);
        const [selectedTxs, setSelectedTxs] = useState<Array<any>>([]);
        const [totalStripeAmount, setTotalStripeAmount] = useState(0);
    
        const getStripeTxs = async () => {
            setStripeOptLoading(true)
            const data = await GlobalService.httpGet(`api/admin/withdrawal/stripe-txs/${tx.transid}`);
            if(data && !data.isError) {
                setOptions(data.stripeTxs);
            }
            setStripeOptLoading(false)
        }
    
        React.useEffect(() => {
            if(open && !stripeOptLoading && options.length === 0) {
                getStripeTxs();
            }
        }, [open]);
    
        const onOptionSelect = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    
            if(totalStripeAmount + parseFloat(value.amount) > parseFloat(tx.amount)) {
                alert("Total amount cannot be greater than transaction amount");
                return;
            }
    
            let _selectedTxIds:Array<string> = selectedTxIds;
            console.log(_selectedTxIds.indexOf(value.transid))
    
            if(_selectedTxIds.indexOf(value.transid) === -1) {
                _selectedTxIds.push(value.transid);
                setSelectedTxIds([..._selectedTxIds]);
                let _selectedTxs:Array<any> = selectedTxs;
                _selectedTxs.push(value);
                setSelectedTxs([..._selectedTxs]);
                setTotalStripeAmount(val => val + parseFloat(value.amount));
            }
            else {
                alert("Transaction already selected");
            }
        }
    
        const initiate = async() => {
            setLoading(true)
            if(totalStripeAmount > parseFloat(tx.amount)) {
                alert("Total amount cannot be greater than transaction amount");
                return;
            }
            const userRes = window.confirm(`Are you sure you want to initiate this withdrawal?`);
            if(!userRes) {
                return;
            }
    
            const data = await GlobalService.httpPost(`api/admin/withdrawal/initiate-stripe`, {
                masterTxId: tx.transid,
                depositTxIds: selectedTxIds
            });
    
            console.log("initiate stripe", data);
    
            if(data && data.failedRefunds && data.failedRefunds.length > 0) {
                alert(`Initiated with stripe, but failed txs: ${JSON.stringify(data.failedRefunds)}`);
                setRefresh(true);
            } else if(data && data.initiated) {
                alert('Withdrawal initiated')
                setRefresh(true);
            } else if (data && !data.isError) {
                alert('Something went wrong. Check logs');
            }
            setLoading(false)
        }
    
        return (
            // <Collapse in={stripeExpanded} timeout="auto" unmountOnExit>
                <div style={{display: "flex", marginTop: 10, flexDirection: "column", border: "1px solid grey", padding: 10, width: 600, borderRadius: 5}} >
                    <Autocomplete
                        size="small"
                        id="stripe-txs"
                        sx={{ width: "100%" }}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        // isOptionEqualToValue={(option, value) => option.transid === value}
                        value={null}
                        getOptionLabel={(option:any) => `${option.transid} - $${option.amount} - ${DateTime.fromISO(option.createdon).setZone("America/New_York").toFormat("ccc, FF a")}`}
                        options={options}
                        loading={stripeOptLoading}
                        blurOnSelect
                        clearOnEscape
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Stripe Txs"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {stripeOptLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                        onChange={onOptionSelect}
                    />
                    {
                        selectedTxIds.length > 0 ?
                        <div style={{display: "flex", marginTop: 10, flexDirection: "column", alignItems: "baseline"}} >
                            {selectedTxs.map((tx, index) => {
                                return (
                                    <span key={tx.transid} >{tx.transid} - ${tx.amount} - {DateTime.fromISO(tx.createdon).setZone("America/New_York").toFormat("ccc, FF a")}</span>
                                )
                            })}
                            Total: ${totalStripeAmount}
                            <Button variant="contained" onClick={initiate} style={{marginTop: 10}} >
                                Initiate with Stripe
                            </Button>
                        </div>
                        : <></>
                    }
                    {/*  */}
                </div>
            // </Collapse>
        )
    }

    const WithdrawButtons = () => {
        const [mercuryAmount, setMercuryAmount] = useState(0)

        const initiateWithMercury = async() => {
            const transId = tx.transid;
            console.log({mercuryAmount, transId});
            if(mercuryAmount <= 0) {
                alert("Amount should be greater than 0");
                return;
            }
            else if(tx.type !== 'Withdraw') {
                alert("Transaction should be withdrawal");
                return;
            }
            else if(mercuryAmount > parseFloat(tx.amount)) {
                alert("Amount should be less than or equal to amount in transaction");
                return;
            } 
            else if (tx.status !== 'pending') {
                alert("Transaction status should be pending");
                return;
            }
    
            const userRes = window.confirm(`Are you sure you want to initiate (mercury) transfer of $${mercuryAmount} ?`);
            if(!userRes) {
                return;
            }
    
            setLoading(true)
            const data = await GlobalService.httpPost(`api/admin/withdrawal/initiate-mercury`, {
                masterTxId: transId,
                amount: mercuryAmount
            });
    
            if(data && data.initiated) {
                alert('Withdrawal initiated')
                setRefresh(true)
            }
            setLoading(false)
        }

        return (
            <>
                {
                    tx.status === 'pending' ? 
                    <>
                        <Button variant="contained" onClick={initiateWithdrawal} style={{marginRight: 10}} >
                            Initiate with WYRE
                        </Button>
                        <Button variant="contained" onClick={()=>changeStatus("initiate", 'withdrawal')} style={{marginRight: 10}} >
                            Initiate
                        </Button>
                    </> : <></>
                }
                {
                    tx.status === 'processing' ?
                        <Button variant="contained" color="success" onClick={()=>changeStatus("success", 'withdrawal')} style={{marginRight: 10}} >
                            Success
                        </Button>
                    : <></>
                }
                <Button variant="contained" color="error" onClick={()=>changeStatus("reject","withdrawal")}>
                    Reject
                </Button>
                <StripeWithdraw tx={tx} />
                <div style={{display: "flex", marginTop: 10}} >
                    <TextField id="outlined-basic" label="Amount" variant="outlined" size="small" onChange={e=> {
                        setMercuryAmount(parseFloat(e.target.value))
                    }} />
                    <Button variant="contained" onClick={initiateWithMercury} style={{marginLeft: 10}} >
                        Initiate with Mercury
                    </Button>
                </div>
            </>
        )
    }

    const getStatusColor = (status: string) => {
        switch(status) {
            case 'pending':
                return '#fbc02d';
            case 'processing':
                return '#fbc02d';
            case 'successful':
                return '#4CAF50';
            case 'rejected':
                return '#F44336';
            default:
                return '#000000';
        }
    }

    return (
        <>
            <TableRow key={index} sx={{ "&:last-child td, &:last-child th" : { border: 0 }, '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    {
                        tab === 'all' ?
                            tx.type === 'Deposit' &&
                            <IconButton aria-label="expand row" size="small" onClick={() => setExpanded(!isExpanded)}
                            >
                                {isExpanded ?
                                    <KeyboardArrowUp /> :
                                    <KeyboardArrowDown />}
                            </IconButton>
                            :
                            <IconButton aria-label="expand row" size="small" onClick={() => setExpanded(!isExpanded)}
                            >
                                {isExpanded ?
                                    <KeyboardArrowUp /> :
                                    <KeyboardArrowDown />}
                            </IconButton>
                    }
                </TableCell>
                <TableCell component="th" scope="row">
                    <Link to={`/users?search=${tx.userid}`}>{tx.userid}</Link>
                </TableCell>
                <TableCell>{tx.transid}</TableCell>
                <TableCell>
                    {tx.effectiveDate
                    ? DateTime.fromISO(tx.effectiveDate).setZone("America/New_York").toFormat("ccc, FF a")
                    : "NA"}
                </TableCell>
                <TableCell>{tx.productCode}</TableCell>
                <TableCell>{parseFloat(tx.amount).toLocaleString()}</TableCell>
                <TableCell style={{color: getStatusColor(tx.status)}} >{tx.status}</TableCell>
                <TableCell>
                    {DateTime.fromISO(tx.createdon).setZone("America/New_York").toFormat("ccc, FF a")}
                </TableCell>
                {
                    tab === "all" && <TableCell>{tx.type}</TableCell>
                }
                {
                    tx.type == 'Withdraw'?null:
                    <TableCell>{tx.deposit_type}</TableCell>
                }
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: isExpanded?10:0, paddingTop: 0 }} colSpan={tab==="all" ? 10 : 8}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        {loading ? <LinearProgress /> :
                            <>
                                {
                                    tab === 'all' ?
                                        tx.type == 'Deposit' && <Button variant="contained" color="success" onClick={() => changeStatus("success", "deposit")}>
                                            Success
                                        </Button>
                                        :
                                        tx.type == 'Withdraw' ?
                                            <WithdrawButtons />
                                            :
                                            <Button variant="contained" color="error" onClick={() => changeStatus("reject", "deposit")}>
                                                Reject
                                            </Button>
                                }
                                <InternalTx masterTxId={tx.transid} isExpanded={isExpanded}></InternalTx>
                            </>}
                    </Collapse>
                </TableCell>
            </TableRow>
        </> 
    )
}

export default Transaction