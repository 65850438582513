import React, { useState } from "react";
import GlobalService from "../../services/global";
import { Button, Skeleton, Typography } from "@mui/material";

type props = {
	userid: string,
	licenseFront: string,
	licenseBack: string,
	userName: string,
	tabValue: number,
}

const VerifyLicense = ({userid, licenseBack, licenseFront, userName, tabValue}:props) => {
	const [bankNames, setBankNames] = useState([]);
	const [status, setStatus] = useState<"REJECTED" | "PENDING" | "APPROVED"| "ITEM_MISSING" | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const fetchBankAccNames = async () => {
		setIsLoading(true);
		const resp = await GlobalService.httpGet(`api/admin/bank-names/${userid}`);

		if (resp && !resp.isError && resp.status) {
			setStatus(resp.status);
			setBankNames(resp.bankAccNames);
		}
		setIsLoading(false);
	}

	React.useEffect(() => {
		if(tabValue===2 && bankNames.length===0) fetchBankAccNames();
	}, [tabValue]);

	const [isSubmiting, setIsSubmiting] = useState(false);
	const handleApproveAndReject = async (action : "reject" | "approve") => {
		setIsSubmiting(true);
		const resp = await GlobalService.httpPost(`api/admin/address/${action}/${userid}`, {});

		if (resp && !resp.isError && resp.updated) {
			setStatus(action==="reject"?"REJECTED":"APPROVED");
		}
		setIsSubmiting(false);
	}

	return (
		<div style={{marginTop: 20, display: tabValue===2?"flex":"none", flexDirection: "column"}} >
			{
				isLoading?
				<Skeleton height={200} />
				:
				<>
					<div style={{display: "flex", justifyContent: "space-between"}} >
						<div>
							<Typography><b>User Name</b>: {userName}</Typography>
							<Typography style={{fontWeight: "bold", marginTop: 10}} >Bank Account Names</Typography>
						</div>
						<div style={{display: "flex"}} >
							{
								status!=="PENDING"?
								<Typography>{status}</Typography>
								:
								<>
									<Button size="medium" variant="contained" color="error" onClick={()=>{handleApproveAndReject("reject")}} style={{marginRight: 30, alignSelf: "baseline"}} disabled={isSubmiting} >Reject</Button>
									<Button size="medium" variant="contained" color="success" onClick={()=>{handleApproveAndReject("approve")}} style={{alignSelf: "baseline"}} disabled={isSubmiting} >Approve</Button>
								</>
							}
						</div>
					</div>
					<div>
						{bankNames.map((name, index) => (
							<Typography key={index}>{index+1}. {name}</Typography>
						))}
					</div>
					<div style={{marginTop: 20, flex: 1}}>
						<img src={licenseFront} alt="license front" style={{width: "50%"}} />
						<img src={licenseBack} alt="license back" style={{width: "50%"}} />
					</div>
				</>
			}
		</div>
	)
}

export default VerifyLicense;