import { Button, Card, Container, FormControl, InputLabel, MenuItem, Paper, Select, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import * as React from 'react';
import GlobalService from '../services/global';
import { DateTime } from 'luxon';

const ReferralsTable = ({referrals, isLoading}:{referrals: Array<any>, isLoading: boolean}) => {
	return (
		<TableContainer component={Paper} style={{marginTop: 30}}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Referrer Id</TableCell>
                <TableCell>Referrer Bonus</TableCell>
                <TableCell>Referee Id</TableCell>
                <TableCell>Created On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    {/* <Skeleton animation={false} /> */}
                  </TableCell>
                </TableRow>
              ) : !referrals[0] ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Typography>No Data</Typography>
                  </TableCell>
                </TableRow>
              ) : (
				referrals.map((referral: any, index) => {
					return (
						<TableRow key={index}>
							<TableCell>{referral.referrerId}</TableCell>
							<TableCell>{referral.referrer_bonus?referral.referrer_bonus:"-"}</TableCell>
							<TableCell>{referral.refereeId}</TableCell>
							<TableCell>{DateTime.fromISO(referral.createdon).toFormat("ccc, FF a")}</TableCell>
						</TableRow>
					)
				})
              )}
            </TableBody>
          </Table>
        </TableContainer>
	)
}

const Referrals = () => {
	const [stats, setStats] = React.useState({
		total: 0,
		successful: 0,
		pending: 0,
		noOfReferrers: 0,
	});
	const [value, setValue] = React.useState(0);
	const [base, setBase] = React.useState<String>("Referrer");
	const [searchId, setSearchId] = React.useState<string|null>(null);
	const [referrals, setReferrals] = React.useState<Array<any>>([]);
	const [isLoading, setIsLoading] = React.useState(false);

	const getStats = async() => {
		const data = await GlobalService.httpGet("api/admin/referrals/stats");
	
		if (!data.isError) {
			setStats({
				total: data.total,
				successful: data.successful,
				pending: data.total - data.successful,
				noOfReferrers: data.no_of_referrers
			})
		}
	}

	const getReferrals = async() => {
		setIsLoading(true);
		const onlyPendings = value===0?1:0;
		const isReferrer = base === "Referrer";
		const searchQuery = onlyPendings || !searchId?"":isReferrer?`&referrerId=${searchId}`:`&refereeId=${searchId}`;
		let query = `api/admin/referrals/all?onlyPending=${onlyPendings}${searchQuery}`;

		const data = await GlobalService.httpGet(query);
	
		if (!data.isError) {
			setReferrals(data.referrals);
		}
		setIsLoading(false);
	}

	React.useEffect(()=> {
		getStats();
	},[])

	React.useEffect(()=>{
		setReferrals([]);
		if(value===0) getReferrals();
	},[value])

	return (
		<Container sx={{ width: "100%", paddingTop: "30px", paddingBottom: "30px"}}>
			<div style={{display: "flex", marginBottom: 30}} >
				<Card>
					<div style={{display: "flex",flexDirection: "row", padding: 20, alignItems: "stretch"}} >
						<div style={{display: "flex",flexDirection: "column", alignItems: "center", marginRight: 60}} >
							<Typography variant="h2" style={{ fontWeight: "400", lineHeight: "60px"}}>{stats.total}</Typography>
							<Typography variant="body2" style={{color: "#2196F3"}}>Total Referrals</Typography>
						</div>
						<div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}} >
							<Typography variant="body1">Successful Referrals: <b>{stats.successful}</b></Typography>
							<Typography variant="body1">Pending Referrals: <b>{stats.pending}</b></Typography>
							<Typography variant="body1">Unique Referrers(users): <b>{stats.noOfReferrers}</b></Typography>
						</div>
					</div>
				</Card>
			</div>
			<Tabs
				value={value}
				aria-label="basic tabs example"
				onChange={(e, val) => setValue(val)}
				style={{ marginTop: "10px" }}
			>
				<Tab label="Pending" />
				<Tab label="All" />
			</Tabs>

			{
				value === 1?
				<div style={{display: "flex", flexDirection: "row", marginTop: 20}} >
					<FormControl>
						<InputLabel id="demo-simple-select-label">Base</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={base}
							label="Base"
							size='small'
							onChange={(event) => setBase(event.target.value as string)}
						>
							<MenuItem value={"Referrer"}>Referrer</MenuItem>
							<MenuItem value={"Referee"}>Referee</MenuItem>
						</Select>
					</FormControl>
					<TextField
						id="outlined-basic"
						label={`Enter ${base} id`}
						variant="outlined"
						size='small'
						style={{marginRight: 30, marginLeft: 10}}
						onChange={(e) => {
							setSearchId(e.target.value);
						}}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								getReferrals();
							}
						}}
					/>
					<Button variant="contained" onClick={getReferrals}>Apply</Button>
				</div>
				:null
			}

			<ReferralsTable referrals={referrals} isLoading={isLoading} />
		</Container>
	)
};

export default Referrals;
