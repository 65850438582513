import * as React from "react";
import { Container, Tabs, Tab } from "@mui/material";

import "./../../assets/scss/App.scss";
import AllTransactions from "./AllTransactions";
import Withdrawal from "./Withdrawal";
import TransactionStats from "./TransactionStats";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && props.children}
    </div>
  );
}

const obj = {
  "withdrawal":0,
  "all":1,
  "stats":2
}

function TransactionView() {
  const [value, setValue] = React.useState(Number.parseInt(sessionStorage.getItem("tx-tab") as string) || 0);

  const handleTab = (value) => {
    sessionStorage.setItem("tx-tab", value);
    setValue(value);
  }

  return (
    <Container sx={{ width: "100%" }}>
      <Tabs
        value={value}
        aria-label="basic tabs example"
        onChange={(e, val) => handleTab(val)}
      >
        <Tab label="Withdrawal" />
        <Tab label="All" />
        <Tab label="Stats" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Withdrawal />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AllTransactions />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TransactionStats />
      </TabPanel>
    </Container>
  );
}

export default TransactionView;
