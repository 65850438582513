import React, { useState, useEffect } from 'react'
import GlobalService from '../../services/global';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    Container,
    FormControlLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
import format from "dateformat";
import { DateTime } from 'luxon';

function InternalTx(inputs: {masterTxId: string, isExpanded: boolean}) {
    const { masterTxId, isExpanded} = inputs
    const [txs, setTxs] = useState<any[]>([])
    const [isLoading, setLoading] = useState(true)

    async function fetchTxs() {
        const data = await GlobalService.httpGet(`api/admin/internalTxs/${masterTxId}`);
        if(data) {
            setTxs(data)
        }
        setLoading(false)
    }

    useEffect(() => {
        if(isExpanded)
            fetchTxs()
    }, [isExpanded])

    function loadingElement() {
        return <TableRow>
        <TableCell colSpan={11}>
          <Skeleton />
          <Skeleton animation="wave" />
          {/* <Skeleton animation={false} /> */}
        </TableCell>
      </TableRow>
    }
    function noDataElement() {
        return <TableRow>
        <TableCell colSpan={11}>
          <Typography>No Data</Typography>
        </TableCell>
      </TableRow>
    }

    return (
      <>
      {
        txs.length === 0?null:
        <TableContainer component={Paper} style={{marginTop: 20}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Tx ID</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Source Amt.</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Dest. Amt.</TableCell>
                <TableCell>Fees</TableCell>
                <TableCell>Asset</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Stage</TableCell>
                <TableCell>CreatedOn</TableCell>
                <TableCell>UpdatedOn</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                null
              ) : !txs[0] ? (
                noDataElement()
              ) : (
                txs.map((tx: any, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {tx.txIdBySource}
                      </TableCell>
                      <TableCell>{tx.source}</TableCell>
                      <TableCell>{tx.sourceAmount}</TableCell>
                      <TableCell>{tx.destination}</TableCell>
                      <TableCell>{tx.destinationAmount}</TableCell>
                      <TableCell>{tx.fees}</TableCell>
                      <TableCell>{tx.asset}</TableCell>
                      <TableCell>{tx.status}</TableCell>
                      <TableCell>{tx.stage}</TableCell>
                      <TableCell>
                        {DateTime.fromISO(tx.createdon).setZone("America/New_York").toFormat("ccc, FF a")}
                      </TableCell>
                      <TableCell>
                        {DateTime.fromISO(tx.updatedon).setZone("America/New_York").toFormat("ccc, FF a")}
                      </TableCell>
                </TableRow>
                
              ))
              )}
            </TableBody>
        </Table>
        </TableContainer>
      }
      </>
    )
}

export default InternalTx