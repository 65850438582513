import React, { useEffect } from 'react';
import { Box, Card, CardContent, CardActions, Button, Typography, Grid, Table, TableRow, TableHead, TableCell, TableBody, Skeleton, Divider } from '@mui/material';
import global from '../../services/global';

const TransactionStats = () => {
    const [depositStats, setDepositStats] = React.useState<any[]>([]);
    const [withdrawStats, setWithdrawStats] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        (async () => {
            const data = await global.httpGet('api/admin/stats');
            // convert it to array then set

            const depStats: any[] = [];
            const withStats: any[] = [];
            Object.entries(data.deposit).forEach(([key, value]) => {
                depStats.push({ status: key, count: value });
            });
            Object.entries(data.withdraw).forEach(([key, value]) => {
                withStats.push({ status: key, count: value });
            })
            setDepositStats(depStats);
            setWithdrawStats(withStats);
            setLoading(false)
        })()
    }, [])

    if (loading) {
        return (
            <Grid container spacing={2} marginTop={2}>
                {
                    [...Array(2)].map((_, index) => (
                        <Grid item xs={6}>
                            <Box key={index}>
                                <Card variant="outlined">
                                    <CardContent sx={{ p: 1 }}>
                                        <Skeleton variant="text" width="60%" height={40} />
                                        <Skeleton variant="text" width="40%" height={30} sx={{ mb: 1.5 }} />
                                        {[...Array(8)].map((_, index) => (
                                            <Box key={index}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Skeleton variant="rectangular" width="100%" height={20} />
                                                </Box>
                                                {index < 7 && <Divider sx={{ my: 1 }} />}
                                            </Box>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
        );
    }

    return (
        <Grid container spacing={2} marginTop={2}>
            <Grid item xs={6}>
                <Box>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Deposit
                            </Typography>
                            <Typography sx={{ mb: 1.5, mt: 1 }} color="text.secondary">
                                Statastics
                            </Typography>
                            <Table size="small" >
                                <TableBody>
                                    {depositStats.map((row) => {
                                        return (
                                            <TableRow
                                                key={row.status}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.status[0].toUpperCase() + row.status.slice(1).replaceAll("_", " ")}: {row.count}{row.status.toLowerCase().includes("rate") ? "%" : ""}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Withdrawal
                            </Typography>
                            <Typography sx={{ mb: 1.5, mt: 1 }} color="text.secondary">
                                Statastics
                            </Typography>

                            <Table size="small" >
                                <TableBody>
                                    {withdrawStats.map((row) => {
                                        return (
                                            <TableRow
                                                key={row.status}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.status[0].toUpperCase() + row.status.slice(1).replaceAll("_", " ")}: {row.count}{row.status.toLowerCase().includes("rate") ? "%" : ""}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
};

export default TransactionStats;
