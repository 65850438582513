import axios from 'axios'
import { NavigateFunction, useNavigate } from 'react-router-dom';

export default class GlobalService {
    static ENDPOINT = process.env.PROD_ENDPOINT
    static BETA_ENDPOINT = process.env.BETA_ENDPOINT
    static PROD_ENDPOINT = process.env.PROD_ENDPOINT
    static token: string|null = null;

    static init() {
        let token = localStorage.getItem('token')
        if(token) {
            console.debug('token exists', token)
            this.setToken(token)
        }
    }

    static setToken(token: string) {
        console.debug('got token', token)
        this.token = token;
        localStorage.setItem('token', token)
        axios.defaults.headers.common['Content-Type'] = "application/json"
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
    }

    static async httpGet(url) {
		if (!this.envCheck()) {
			return null;
		}
        console.log(`httpGet: ${url}`)
        url = `${this.ENDPOINT}/${url}`
        try {
            let resp = await axios.get(url)
            console.log('httpGet resp', resp.data)
            if(resp.data.isError || resp.data.error) {
                alert(`Error: ${resp.data.error}`)
                return null;
            } else {
                return resp.data;
            }
        } catch(err) {
            console.error('error httpGet', url, err, err.message)
            alert(`Error occured httpGet: ${url} : ${JSON.stringify(err.response?.data?.error || err.message)}`)
            return null;
        }
    }

	static envCheck() {
		if (localStorage.getItem('isTestFlight') == 'true' && this.ENDPOINT === this.PROD_ENDPOINT) {
			alert('Endpoint and environment mismatch. Please close the tab and reopen the app.');
			return false;
		}
		else if (localStorage.getItem('isTestFlight') != 'true' && this.ENDPOINT === this.BETA_ENDPOINT) {
			alert('Endpoint and environment mismatch. Please close the tab and reopen the app.');
			return false;
		}
		return true;
	} 

    static async httpPost(url, data) {
		if (!this.envCheck()) {
			return null;
		}
        console.log(`httpPost: ${url}`)
        url = `${this.ENDPOINT}/${url}`
        try {
            let resp = await axios.post(url, data)
            console.log('httpPost resp', resp.data)
            if(resp.data.isError || resp.data.error) {
                alert(`Error: ${resp.data.error}`)
                return null;
            } else {
                return resp.data;
            }
        } catch(err) {
            console.error('error httpPost', url, data, err, err.message)
            alert(`Error occured httpPost: ${url} : ${JSON.stringify(err.response?.data?.error || err.message)}`)
            return null;
        }
    }

    static logout() {
        localStorage.removeItem('token')
        this.token = null;
        axios.defaults.headers.common = {}
    }
}