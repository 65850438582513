import { useEffect, useState } from "react";
import { Container, Paper, 
    Typography, Box, TextField,
    Skeleton
} from "@mui/material";
import GlobalService from "../../services/global";
import theme from "../../assets/theme";
import User from './User';

function FailedKYCs() {
    const [users, setUsers] = useState([])
    const [initiated, setInitiated] = useState(false)

    const getCards = () => {
        let cards = []
        users.forEach(user=>{
            cards.push(<User user={user} key={user.id}/>)
        })
        return cards;
    }
    async function fetchUsers() {
      let resp = await GlobalService.httpGet('api/admin/failedKYCs')
      console.debug('fetchUsers', resp)
      const tempRes = [
        {
          accountFields:
            '[{"fieldType":"EMAIL","value":"tandelneelkanth@gmail.com","note":null,"status":"APPROVED","updatedAt":"1660832469000","fieldId":"individualEmail"},{"fieldType":"CELLPHONE","value":"+11122233334","note":null,"status":"APPROVED","updatedAt":"1660832469000","fieldId":"individualCellphoneNumber"},{"fieldType":"STRING","value":"Alberta Bobbeth Charleson","note":null,"status":"APPROVED","updatedAt":"1660832772526","fieldId":"individualLegalName"},{"fieldType":"STRING","value":"REDACTED","note":null,"status":"APPROVED","updatedAt":"1660832772526","fieldId":"individualSsn"},{"fieldType":"DATE","value":"2002-04-02","note":null,"status":"APPROVED","updatedAt":"1660832772526","fieldId":"individualDateOfBirth"},{"fieldType":"ADDRESS","value":{"street1":"2493 Leisure Lane","street2":null,"city":"San Matias","state":"CA","postalCode":"93405-2255","country":"US"},"note":null,"status":"APPROVED","updatedAt":"1660832772526","fieldId":"individualResidenceAddress"},{"fieldType":"DOCUMENT","value":["DO_WGRNL27U638","DO_7TD3XYW8GXM"],"note":null,"status":"APPROVED","updatedAt":"1660832772519","fieldId":"individualGovernmentId"},{"fieldType":"PAYMENT_METHOD","value":"PA_43RDJNB93XX","note":null,"status":"APPROVED","updatedAt":"1660832772516","fieldId":"individualSourceOfFunds"},{"fieldType":"DOCUMENT","value":[],"note":null,"status":"APPROVED","updatedAt":null,"fieldId":"individualProofOfAddress"}]',
          accountId: "AC_8WGDZUT4CQ4",
          accountStatus: "OPEN",
          accountSubscribeID: "DA_3QQNEY6TFBQ",
          createdon: "2022-08-18T14:21:24.434Z",
          id: "3",
          paymentMethodId: "PA_43RDJNB93XX",
          paymentMethodStatus: "ACTIVE",
          paymentSubscribeID: "DA_WGV34D9NP4P",
          supportMailSent: false,
          updatedon: "2022-08-23T04:07:32.444Z",
          user: {
            accruedInterest: "0",
            additional_user_info: [
              {
                addressProof: null,
                city: "San Matias",
                confirmed: true,
                country: "US",
                createdon: "2022-08-17T18:18:42.422Z",
                dateOfBirth: "2002-04-02",
                id: "20",
                identityNumber: "REDACTED",
                identityType: "SSN",
                phoneNumber: "+11122233334",
                postalCode: "93405-2255",
                state: "CA",
                street1: "2493 Leisure Lane",
                street2: null,
                updatedon: "2022-08-18T14:20:55.333Z",
              },
            ],
            balance: "0",
            createdon: "2022-08-17T18:15:37.343Z",
            credit_type: "SIGNUP",
            earnings: "0",
            email: "tandelneelkanth@gmail.com",
            id: "20",
            imageback: "",
            imagefront: "",
            levelId: "2",
            name: "Alberta Bobbeth Charleson",
            referralCode: "TANDELNEEL29327",
            updatedon: "2022-08-18T14:26:28.191Z",
            userRole: "USER",
            user_kyc_status: [
              {
                addressProof: "INCOMPLETE",
                addressProofStatus: null,
                bankAccount: "OPEN",
                bankAccountRemarks: "abcd",
                createdon: "2022-08-17T18:15:46.565Z",
                id: "20",
                identityNumber: "APPROVED",
                identityNumberRemarks: null,
                license: "OPEN",
                licenseRemarks: null,
                overallRemarks: null,
                overallStatus: "APPROVED",
                updatedon: "2022-08-18T14:26:26.279Z",
                userInfo: "APPROVED",
                userInfoRemarks: null,
              },
            ],
            weeklyEarning: "0",
          },
          userid: "20",
        },
      ];

      setUsers(resp);
      setInitiated(true);
    }

    useEffect(()=>{
        fetchUsers()
    }, [])

    return (
        <Container sx={{width: '100%', padding: '10px'}}>
            {!initiated && <div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>}
            {initiated && users.length && getCards()}
            {initiated && users.length==0 && <Typography variant="h4">
                No Failed KYCs    
            </Typography>}
        </Container>
    )
}

export default FailedKYCs;