import React, { useState } from 'react';
import GlobalService from '../../services/global';
import { Button, Checkbox, Collapse, FormControl, FormControlLabel, FormLabel, IconButton, Paper, Radio, RadioGroup, Skeleton, TextareaAutosize, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { DateTime } from 'luxon';

type cardProps = {
	mode: "EDIT" | "VIEW",
	question: any,
	expandedId: string|null,
	setExpandedId: Function,
	setRefresh: Function,
}

const QuestionCard = ({mode, question, expandedId, setExpandedId, setRefresh}: cardProps) => {
	const [isPublic, setIsPublic] = useState<boolean|null>(null);
	const [answer, setAnswer] = useState(question.answer);
	const [_mode, set_Mode] = useState(mode);

	const updateQuestion = async() => {
		const resp = await GlobalService.httpPost(`api/admin/questions/${question.id}`, {
			answer: answer,
			isPublic: isPublic,
			shouldSendEmail: question.answer?false:true
		});

		console.debug("updateQuestion", resp);
		if(resp && !resp.isError && resp.question) {
			alert("Question updated successfully");
			setRefresh(true);
		}
	}

	const handleSubmit = () => {
		if(!answer) {return};
		
		//prompt confirmation
		const userRes = window.confirm(`Do you want to submit the answer?\nYou have selected${isPublic?"":" not"} public`);
		if(!userRes) {
			return;
		}
		updateQuestion();
	}

	return (
		<Paper style={{marginBottom: 20, padding: "10px 20px", width: "100%", flex: 1, boxSizing: "border-box"}} >
			<div style={{display: "flex", justifyContent: "space-between", marginTop: 5}} >
				<div style={{display: "flex"}} >
					<Typography style={{opacity: 0.7, marginRight: 30, fontSize: 14}}>{DateTime.fromISO(question.createdon).setZone("America/New_York").toFormat("ccc, FF a")}</Typography>
					<Typography style={{opacity: 0.7, fontSize: 14, marginRight: 30}}>Asked By UID: {question.asked_by_id}</Typography>
					<Typography style={{opacity: 0.7, fontSize: 14, marginRight: 30}}>Likes: {question.total_likes}</Typography>
					<Typography style={{opacity: 0.7, fontSize: 14, marginRight: 30}}>Dislikes: {question.total_dislikes}</Typography>
				</div>
				{
					question.answered_by_id &&
					<div style={{display: "flex"}} >
						<Typography style={{opacity: 0.7, marginRight: 30, fontSize: 14}}>Answered By UID: {question.answered_by_id}</Typography>
						<Typography style={{color:question.public?"#1A5E20":"#D3302F", fontSize: 14}}>{question.public?"Public":"Not Public"}</Typography>
					</div>
				}
			</div>
			<div 
				style={{display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", marginTop: 5, cursor: "pointer"}}
				onClick={()=> setExpandedId(expandedId===question.id?null:question.id)}
			>
				<div style={{display: "flex", flexDirection: "column"}}>
					<Typography>{question.question}</Typography>
				</div>
				<IconButton aria-label="expand row" size="small" onClick={()=> setExpandedId(expandedId===question.id?null:question.id)}>
					{expandedId === question.id ?
					<KeyboardArrowUp /> :
					<KeyboardArrowDown />}
				</IconButton>
			</div>
			<Collapse in={expandedId===question.id}  timeout="auto" unmountOnExit>
				{
					_mode==="EDIT"?
					<div style={{display: "flex", flexDirection: "column", marginBottom: 10, marginTop: 15}}>
						<TextareaAutosize maxRows={5} minRows={3} placeholder="Write answer here" style={{width: "100%", padding: 10, boxSizing: "border-box"}} onChange={(event) => {setAnswer(event.target.value)}} value={answer} />
						<div style={{display: "flex", flexDirection: "row", marginTop: 10, alignItems: "center"}} >
							<div style={{display: "flex", alignItems: "center", marginRight: 5}} onClick={()=>{setIsPublic(true)}} >
								<Radio 
									checked={isPublic?true:false}
									onClick={() => {
										setIsPublic(!isPublic);
									}}
								/>
								<Typography style={{fontSize: 14}}>public</Typography>
							</div>
							<div style={{display: "flex", alignItems: "center"}} onClick={()=>{setIsPublic(false)}} >
								<Radio 
									checked={isPublic===false?true:false}
									onClick={() => {
										setIsPublic(!isPublic);
									}}
								/>
								<Typography style={{fontSize: 14}}>not public</Typography>
							</div>
							<Button variant="contained" color="success" onClick={handleSubmit} style={{alignSelf: "baseline", marginLeft: 30}} disabled={isPublic===null} >
								Submit
							</Button>
						</div>
					</div>
					:
					<div style={{display: "flex", flexDirection: "column"}}>
						<Typography style={{marginBottom: 10, opacity: 0.8}}>{question.answer}</Typography>
						<Button variant="contained" color="primary" onClick={()=>{set_Mode("EDIT")}} style={{alignSelf: "baseline"}} >
							Edit
						</Button>
					</div>
				}
			</Collapse>
		</Paper>
	)
}

type filterProps = {
	questions: [];
	setFilteredQues: Function;
}

const Filters = ({questions, setFilteredQues}:filterProps) => {
	const [filters, setFilters] = useState({public: false, answered: false});

	const handleFilter = (_filter:"public"|"answered") => {
		let _filters = filters;
		_filters[_filter] = !_filters[_filter];
		setFilters(_filters);

		if(!_filters.answered && !_filters.public) {
			return handleNoFilters();
		}

		const _filteredQue = questions.filter((que:any) => {
			if(_filters.answered && _filters.public){
				if (que.public && que.answered_by_id) return true;
			} else {
				if(_filters.public && que.public) return true;
				else if(_filters.answered && que.answered_by_id) return true;
			}
			return false
		});

		setFilteredQues([..._filteredQue]);
	}

	const handleNoFilters = () => {
		setFilters({answered: false, public: false});
		setFilteredQues([...questions]);
	}

	return (
		<div style={{display: "flex", alignItems: "center"}} >
	        <Typography style={{ marginRight: "10px" }}>Filters: </Typography>
			<FormControlLabel
              control={
                <Checkbox
                  checked={!filters.answered && !filters.public}
                  onChange={handleNoFilters}
                />
              }
              label={"No Filters (All)"}
            />
			<FormControlLabel
              control={
                <Checkbox
                  checked={filters.public}
                  onChange={()=>{handleFilter("public")}}
                />
              }
              label={"Public"}
            />
			<FormControlLabel
              control={
                <Checkbox
					checked={filters.answered}
					onChange={()=>{handleFilter("answered")}}
                />
              }
              label={"Answered"}
            />
		</div>
	)
}

type props = {
	mode: "NOT_ANSWERED" | "ALL",
	tabValue: number
}

const QuestionsTab = ({mode,tabValue}:props) => {
	const [questions, setQuestions] = useState<[]>([]);
	const [expandedId, setExpandedId] = useState<string|null>(null);
	const [refresh, setRefresh] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [filteredQues, setFilteredQues] = useState(questions);

	const fetchQuestions = async () => {
		setIsFetching(true);
		const endpoint = mode==="ALL"?"api/admin/questions":"api/admin/questions/not-answered";

		const resp = await GlobalService.httpGet(endpoint);

		if (resp && !resp.isError && resp.questions) {
			setQuestions(resp.questions);
			setFilteredQues(resp.questions);
		}
		setIsFetching(false);
	}

	React.useEffect(() => {
		if((mode==="NOT_ANSWERED"&&tabValue===0) || (mode==="ALL"&&tabValue===1)) fetchQuestions();
	}, [tabValue]);

	React.useEffect(()=> {
		console.log("here ",refresh);
		if(refresh && !isFetching) fetchQuestions();
	},[refresh])

	return (
		<>
			<div style={{display: (mode==="NOT_ANSWERED"&&tabValue===0) || (mode==="ALL"&&tabValue===1)?"flex":"none", flexDirection: "column"}} >
				{
					mode==="ALL" && <Filters questions={questions} setFilteredQues={setFilteredQues} />
				}
				<div style={{display: "flex", flexDirection: "column", marginTop: 10}}>
					{
						isFetching?
						<>
							<Skeleton height={"130px"} />
							<Skeleton height={"130px"} />
							<Skeleton height={"130px"} />
						</>
						:
						filteredQues.length===0?"No data":
						filteredQues.map((question:any) => {
							return (
								<QuestionCard key={question.id} mode={mode==="NOT_ANSWERED"?"EDIT":"VIEW"} question={question} expandedId={expandedId} setExpandedId={setExpandedId} setRefresh={setRefresh} />
							)
						})
					}
				</div>
			</div>
		</>
	)
};

export default QuestionsTab;