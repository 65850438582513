import {
  Checkbox,
  TextField,
  Autocomplete,
  Input,
  IconButton,
  Button,
  Modal,
  Box,
  Stack,
  Grid,
} from "@mui/material";
import { DateRange } from 'react-date-range';
import {
  CheckBoxOutlineBlank,
  CheckBox,
  Search as SearchIcon,
  CalendarToday as CalendarTodayIcon
} from "@mui/icons-material";
import * as React from "react";
import Global from "../../services/global";
import { DateTime } from "luxon";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const UserIdInputComponent = ({userTx, setUserTx, handleUserIdFilter}) =>
  (
    <Box sx={{ border: '1px solid #ccc', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
      <Input
        sx={{ '--Input-decoratorChildHeight': '0px', p: 0, m: 0.3, ml: 0.5 }} // Adjust width here
        placeholder="Enter User Id"
        type="text"
        required
        value={userTx.userId}
        onChange={(event) => setUserTx({ ...userTx, userId: event.target.value, status: '' })}
        error={userTx.status === 'failure'}
        disabled={userTx.status === 'loading'}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleUserIdFilter(e);
          }
        }}
      />
      <IconButton onClick={handleUserIdFilter} sx={{ pr: 1 }} aria-label="search" disabled={userTx.status === 'loading'}>
        <SearchIcon />
      </IconButton>
    </Box>
  );

const FilterComponent = ({ tab, setTxs, allTxs, setIsLoading, setAllTxs }: { tab: string, setTxs: Function, allTxs: any[], setIsLoading: Function, setAllTxs: Function }) => {
  console.log("++++++++++")
  console.log(tab)
  const [selected_status, setSelectedStatus] = React.useState(()=>{
    if(sessionStorage.getItem(`${tab}-tx-status`) != null) {
      if(sessionStorage.getItem(`${tab}-tx-status`)?.length ?? 0 > 0)
        return sessionStorage.getItem(`${tab}-tx-status`)?.split(",");
      else
        return [];
    } else
      return [tab === "withdrawal" ? "pending" : "successful"] as any
  });
  const [tx_types, setTxTypes] = React.useState(() => {
    if(sessionStorage.getItem(`${tab}-tx-types`) != null) {
      if (sessionStorage.getItem(`${tab}-tx-types`)?.length ?? 0 > 0)
        return sessionStorage.getItem(`${tab}-tx-types`)?.split(",");
      else
        return [];
    } else
      return tab === "withdrawal" ? ["Withdraw"] : ["Deposit"] as any
  });
  const [product_codes, setProductCodes] = React.useState(() => {
    if (sessionStorage.getItem(`${tab}-tx-product-codes`) != null) {
      if (sessionStorage.getItem(`${tab}-tx-product-codes`)?.length ?? 0 > 0)
        return sessionStorage.getItem(`${tab}-tx-product-codes`)?.split(",");
      else
        return [];
    } else
      return [] as any
  });

  const txTypes = [
    "Deposit",
    "Withdraw",
    "Recurring Deposit",
    "Signup Reward",
    "Weekly Earnings",
    "Free Credit Weekly Earnings",
    "Thanksgiving Bonus",
    "Gift",
    "Wire Credit",
    "Free Credit",
    "Loan Debit",
    "Referral Bonus"
  ]

  const statuses = [
    "initiated",
    "investing",
    "pending",
    "processing",
    "rejected",
    "successful",
    "rewarded"
  ];

  const productCodes = [
    "PRINCIPAL_PROTECTED",
    "FDIC_INSURED",
    "SPECIAL_OFFER",
    "SPECIAL_OFFER_2",
    "GOVT_BONDS",
    "NEEV_AGGRESSIVE"
  ]

  const [selectedDateRange, setSelectedDateRange] = React.useState({
    startDate: (() => {
      if (sessionStorage.getItem(tab + "-tx-from") != null) {
        if (sessionStorage.getItem(tab + "-tx-from") != "")
          return new Date(sessionStorage.getItem(tab + "-tx-from") as string);
      }
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      return thirtyDaysAgo;
    })(),
    endDate: (() => {
      if (sessionStorage.getItem(tab + "-tx-to") != null) {
        if (sessionStorage.getItem(tab + "-tx-to") != "")
          return new Date(sessionStorage.getItem(tab + "-tx-to") as string);
      }
      return new Date()
    })(),
    maxDate: new Date(),
    key: "selection",
    status: sessionStorage.getItem(tab + "-tx-from") == "" && sessionStorage.getItem(tab + "-tx-to") == "" ? "cleared" : "selected"
  });
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [selectedDates, setSelectedDates] = React.useState(selectedDateRange); // State to store selected dates

  const handleDateChange = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
  }
  const handleDateChangeSubmit = () => {
    setShowDatePicker(false);

    const adjustedStartDate = new Date(selectedDateRange.startDate);
    adjustedStartDate.setHours(0, 0, 0, 0);
    const adjustedEndDate = new Date(selectedDateRange.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    setSelectedDates({
      startDate: adjustedStartDate,
      endDate: adjustedEndDate,
      maxDate: new Date(),
      key: "selection",
      status: "selected"
    });
  }

  const fetchFilteredTxs = async () => {
    setIsLoading(true);

    let reqStr = `api/admin/txs?`;

    if (tab === "withdrawal") {
      reqStr += "&type=Withdraw";
    } else if (tx_types.length > 0) {
      reqStr += `&type=${tx_types.join(",")}`;
    }

    if (selected_status.length > 0) {
      reqStr += `&status=${selected_status.join(",")}`;
    }

    if (product_codes.length > 0) {
      reqStr += `&product_codes=${product_codes.join(",")}`;
    }

    if (selectedDates.status === "selected") {
      reqStr += `&from=${selectedDates.startDate.toISOString()}&to=${selectedDates.endDate.toISOString()}`;
    }
    console.log("======reqStrFilterComponent=======")
    console.log(reqStr)
    const txs = await Global.httpGet(reqStr);

    setIsLoading(false);
    setAllTxs(txs);
    setTxs(txs);

    sessionStorage.setItem(tab + "-" + "tx-status", selected_status.join(","));
    sessionStorage.setItem(tab + "-" + "tx-types", tx_types.join(","));
    sessionStorage.setItem(tab + "-" + "tx-product-codes", product_codes.join(","));
    if (selectedDates.status === "selected") {
      sessionStorage.setItem(tab + "-" + "tx-from", selectedDates.startDate.toISOString());
      sessionStorage.setItem(tab + "-" + "tx-to", selectedDates.endDate.toISOString());
    } else {
      sessionStorage.setItem(tab + "-" + "tx-from", "");
      sessionStorage.setItem(tab + "-" + "tx-to", "");
    }
  }

  const handleTxStatus = (statuses: string[]) => {
    setSelectedStatus(statuses);
  }

  const handleProductCodes = (product_codes: string[]) => {
    setProductCodes(product_codes);
  }

  const handleTxType = (tx_types: string[]) => {
    setTxTypes(tx_types);
  }

  const clearFilters = () => {
    setSelectedStatus([...[]]);
    setTxTypes([...[]]);
    const newRange = {
      startDate: new Date(),
      endDate: new Date(),
      maxDate: new Date(),
      key: "selection",
      status: "cleared"
    };
    setProductCodes([...[]]);
    setSelectedDates(newRange);
    setSelectedDateRange(newRange);
    setUserTx({ userId: "", status: "" });
  }

  const [userTx, setUserTx] = React.useState<{ userId: string, status: string }>({ userId: "", status: "" });
  const handleUserIdFilter = (e: any) => {
    console.log(allTxs)
    e.preventDefault();
    setUserTx({ ...userTx, status: "loading" });
    try {
      if (userTx.userId === "" || isNaN(parseInt(userTx.userId))) {
        alert("Please enter a valid user id");
        setUserTx({ userId: "", status: "failed" });
        return;
      }
      console.log(userTx.userId);
      const userTxs = allTxs.filter(tx => tx.userid === userTx.userId)
      setTxs(userTxs);
      setUserTx({ ...userTx, status: "success" });
    }
    catch (e) {
      alert("Error occured while fetching transactions");
      setUserTx({ userId: "", status: "failed" });
    }
  }
  
  return (
    <>
      <Grid container spacing={1} marginTop={2}>
        <Grid item xs={2.5}>
          <Autocomplete
            id="size-small-outlined-multi"
            size="small"
            multiple
            limitTags={1}
            options={statuses}
            getOptionLabel={(s) => s}
            onChange={(event, value) => handleTxStatus(value)}
            value={selected_status}
            renderOption={(props, s, { selected }) => (
              <li {...props}>
                <Checkbox
                  key={s}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {s}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Select status" />
            )}
          />
        </Grid>

        {tab === "all" &&
          <Grid item xs={2.5}>
            <Autocomplete
              multiple
              limitTags={1}
              id="size-small-outlined-multi"
              size="small"
              options={txTypes}
              getOptionLabel={(s) => s}
              onChange={(event, value) => handleTxType(value)}
              value={tx_types}
              renderOption={(props, s, { selected }) => (
                <li {...props}>
                  <Checkbox
                    key={s}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {s}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Select transaction type" />
              )}
            />
          </Grid>
        }

        <Grid item xs={2.5}>
          <Autocomplete
            multiple
            id="size-small-outlined-multi"
            size="small"
            limitTags={1}
            options={productCodes}
            getOptionLabel={(s) => s}
            onChange={(event, value) => handleProductCodes(value)}
            value={product_codes}
            renderOption={(props, s, { selected }) => (
              <li {...props}>
                <Checkbox
                  key={s}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {s}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Select product type" />
            )}
          />
        </Grid>

        <Grid item xs={2.5}>
          <Box sx={{ border: '1px solid #ccc', borderRadius: '5px', p: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              {selectedDates.status === "selected" ? (
                selectedDates.startDate.toDateString() === selectedDates.endDate.toDateString() ? (
                  DateTime.fromISO(selectedDates.startDate.toISOString()).toFormat("dd/MM/yyyy")
                ) : (
                  `${DateTime.fromISO(selectedDates.startDate.toISOString()).toFormat("dd/MM/yy")} - ${DateTime.fromISO(selectedDates.endDate.toISOString()).toFormat("dd/MM/yy")}`
                )
              ) : (
                "Select dates"
              )}
            </Box>
            <Button onClick={() => setShowDatePicker(!showDatePicker)} variant="outlined" size="small" sx={{ height: '75%', ml: 1, px: 0 }}>
              <CalendarTodayIcon sx={{ width: 30 }} />
            </Button>
          </Box>
        </Grid>

        <Grid item xs={1}>
          <Button variant="contained" color="primary" onClick={fetchFilteredTxs}>
            Apply
          </Button>
        </Grid>

        {tab === "withdrawal" &&
          <Grid item xs={2.5}>
            <UserIdInputComponent userTx={userTx} setUserTx={setUserTx} handleUserIdFilter={handleUserIdFilter} />
          </Grid>
        }

        <Grid item xs={1}>
          <Button variant="outlined" color="error" onClick={clearFilters}>
            Clear
          </Button>
        </Grid>
      </Grid>

      {/* second row */}
      {tab === "all" &&
        <Box sx={{ mt: 1, width: 225 }}>
            <UserIdInputComponent userTx={userTx} setUserTx={setUserTx} handleUserIdFilter={handleUserIdFilter} />
        </Box>
      }

      <Modal
        open={showDatePicker}
        onClose={() => setShowDatePicker(false)}
        aria-labelledby="date-range-modal"
        aria-describedby="date-range-modal"
      >
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', paddingBottom: '20px' }}>
          <DateRange
            onChange={handleDateChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={[selectedDateRange]}
            direction="horizontal"
          />
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <Button onClick={() => setShowDatePicker(false)} variant="outlined" color="error" >Close</Button> {/* Button to close the modal */}
            <Button onClick={handleDateChangeSubmit} variant="contained" color="primary" style={{ marginLeft: '10px' }}>Done</Button> {/* Submit button */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FilterComponent;
