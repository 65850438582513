import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import GlobalService from '../services/global';
import { useNavigate } from 'react-router-dom';


export default function MenuAppBar() {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let isTestFlight = localStorage.getItem('isTestFlight')
  const [isTestflight, setTestflight] = React.useState(isTestFlight=='true');
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	if(GlobalService.token) {
		alert('Please logout to change the environment');
		return;
	}
	
    let isTestFlight = event.target.checked;
    setTestflight(isTestFlight);
    if(isTestFlight) {
      GlobalService.ENDPOINT = GlobalService.BETA_ENDPOINT
    } else {
      GlobalService.ENDPOINT = GlobalService.PROD_ENDPOINT
    }
    localStorage.setItem('isTestFlight', isTestFlight.toString())
  };

  React.useEffect(() => {
	if(isTestflight) {
	  GlobalService.ENDPOINT = GlobalService.BETA_ENDPOINT
	} else {
	  GlobalService.ENDPOINT = GlobalService.PROD_ENDPOINT
	}
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    navigate('/login')
    GlobalService.logout();
  };

  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Admin | Neev
          </Typography>

            <div>
				{GlobalService.token?
				<>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleMenu}
						color="inherit"
					>
						<AccountCircle />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
						}}
						// keepMounted
						onClose={()=>setAnchorEl(null)}
						transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
						}}
						open={Boolean(anchorEl)}
					>
						<MenuItem onClick={handleClose}>Logout</MenuItem>
					</Menu>
				</>
				:
				<FormControlLabel control={
					<Switch color="secondary" onChange={handleChange} checked={isTestflight} />
				  } label={isTestflight ? 'Testflight on' : 'Testflight off'} />
			  }
            </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}