import { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Button,
  Card,
  LinearProgress,
  Snackbar,
  Alert,
  AlertColor,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import GlobalService from "../../services/global";
import theme from "../../assets/theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RejectOptions from "../../components/RejectOptions";

function User({ user }) {
  interface Message {
    message: string;
  }
  const [_user, setUser] = useState(user);
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState<Message>({ message: "" });
  const [toastOpen, setToastOpen] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const [userInfo, setUserInfo] = useState({
    name: user.user.name,
    phoneNumber: user.user.additional_user_info[0]?.phoneNumber,
    dateOfBirth: user.user.additional_user_info[0]?.dateOfBirth,
    street1: user.user.additional_user_info[0]?.street1,
    street2: user.user.additional_user_info[0]?.street2,
    city: user.user.additional_user_info[0]?.city,
    state: user.user.additional_user_info[0]?.state,
    country: user.user.additional_user_info[0]?.country,
    postalCode: user.user.additional_user_info[0]?.postalCode,
  });
  const labelsMapping = [
    { statusField: "license", remarksField: "licenseRemarks" },
    { statusField: "bankAccount", remarksField: "bankAccountRemarks" },
    { statusField: "userInfo", remarksField: "userInfoRemarks" },
    { statusField: "identityNumber", remarksField: "identityNumberRemarks" },
  ];

  const labels = ["License", "Bank Account", "Basic Info", "SSN"];

  const computeWyreErrors = (profileFields) => {
    profileFields = JSON.parse(profileFields);
    let openFields = [];
    if(!profileFields)
      return openFields
    for (let i = 0; i < profileFields.length; ++i) {
      let profileField = profileFields[i];
      if (profileField.note || profileField.status != "APPROVED") {
        if (profileField.status == "INCOMPLETE") {
          openFields.push(
            <Alert severity={"error"} key={profileField.fieldId}>
              {profileField.fieldId} - {profileField.status} - "
              {profileField.note}"
            </Alert>,
          );
        } else if (profileField.note) {
          openFields.push(
            <Alert severity={"warning"}>
              {profileField.fieldId} - {profileField.status} -{" "}
              {profileField.note}
            </Alert>,
          );
        } else {
          openFields.push(
            <Alert severity={"warning"}>
              {profileField.fieldId} - {profileField.status}
            </Alert>,
          );
        }
      }
    }
    return openFields;
  };

  const computeRemarks = (kycStatus) => {
    let labelsWithRemarks = [];
    for (let i = 0; i < labelsMapping.length; ++i) {
      let labelInfo = labelsMapping[i];
        labelsWithRemarks.push(labelInfo);
    }
    return labelsWithRemarks.map((label, index) => (
      <Typography style={{ width: "100%" }} key={label.statusField}>
        <Typography style={{ color: "#F5AC37" }}>{labels[index]}: {kycStatus[label.statusField]}</Typography>{" "}
        {kycStatus[label.remarksField]}
      </Typography>
    ));
  };

  const [updatedKYCStatus, setUpdateKYCStatus] = useState({});
  const defaultRemarks = {
    licenseRemarks: ["Include all four corners and edges of the license", "Need high quality image with proper lighting without glare", "Only govt. issued license accepted"],
    bankAccountRemarks: ["Unsupported bank"],
    userInfoRemarks: ["Mobile numbers only (no landline/VoIP numbers)", "Fullname must be as in license", "Date of birth should be as license"],
    identityNumberRemarks: [],
  };

  useEffect(() => {
    labelsMapping.map((ele) => {
      let temp_current_status = {};

      let status = _user.user.user_kyc_status[0][ele.statusField];
      let remarks = _user.user.user_kyc_status[0][
        ele.remarksField
      ]
        ? _user.user.user_kyc_status[0][ele.remarksField]
        : defaultRemarks[ele.remarksField][0];
      
      temp_current_status[ele.statusField] = status

      temp_current_status[ele.remarksField] = status == 'INCOMPLETE' ? remarks : null

      setUpdateKYCStatus((currentState) => ({
        ...currentState,
        ...temp_current_status,
      }));
    });
  }, []);

  // const RejectOptionsComponent = () => {
  //   return labelsMapping.map((ele, index) => {
  //     const [showOther, setShowOther] = useState(
  //       _user.user.user_kyc_status[0][ele.remarksField] ||
  //         !defaultRemarks[ele.remarksField][0],
  //     );

  //     const handleStatusOrRemarkChange = (
  //       event:
  //         | SelectChangeEvent
  //         | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  //       field: string,
  //     ) => {
  //       console.log("before update: ", updatedKYCStatus);

  //       let temp = updatedKYCStatus;

  //       if (event.target.value === "other") {
  //         return setShowOther(true);
  //       }

  //       temp[field] = event.target.value as string;

  //       setUpdateKYCStatus({ ...temp });
  //     };

  //     return (
  //       <>
  //         <p>{ele.statusField}</p>
  //         <div style={{ display: "flex", flexDirection: "row" }}>
  //           <FormControl sx={{ minWidth: 150 }}>
  //             <InputLabel id="status-options">Status</InputLabel>
  //             <Select
  //               labelId="status-options"
  //               id="demo-simple-select"
  //               value={updatedKYCStatus[ele.statusField]}
  //               label="Status"
  //               size="small"
  //               onChange={(event) =>
  //                 handleStatusOrRemarkChange(event, ele.statusField)
  //               }
  //             >
  //               {status.map((st) => (
  //                 <MenuItem value={st}>{st}</MenuItem>
  //               ))}
  //             </Select>
  //           </FormControl>
  //           <FormControl sx={{ marginLeft: "20px", width: "100%" }}>
  //             <InputLabel id="remark-options">Remark</InputLabel>

  //             <Select
  //               labelId="remark-options"
  //               id="remark-options"
  //               value={showOther ? "other" : updatedKYCStatus[ele.remarksField]}
  //               label="Remark"
  //               size="small"
  //               onChange={(event) =>
  //                 handleStatusOrRemarkChange(event, ele.remarksField)
  //               }
  //             >
  //               {defaultRemarks[ele.remarksField].map((rm) => (
  //                 <MenuItem value={rm}>{rm}</MenuItem>
  //               ))}
  //               <MenuItem value={"other"}>Other</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //         {showOther ? (
  //           <Box
  //             component="form"
  //             // sx={{
  //             //   "& .MuiTextField-root": { m: "10px 0", width: "50%" },
  //             // }}
  //             noValidate
  //             autoComplete="off"
  //           >
  //             <TextField
  //               id="other-remark"
  //               label="Other Remark"
  //               variant="outlined"
  //               size="small"
  //               sx={{ width: "100%", marginTop: "20px" }}
  //               value={updatedKYCStatus[ele.remarksField]}
  //               onChange={(event) =>
  //                 handleStatusOrRemarkChange(event, ele.remarksField)
  //               }
  //             />
  //           </Box>
  //         ) : null}
  //         {index !== labelsMapping.length - 1 && (
  //           <Divider sx={{ marginTop: "10px" }} />
  //         )}
  //       </>
  //     );
  //   });
  // };

  const saveUserInfo = async () => {
    setDisabled(true);
    let resp = await GlobalService.httpPost(
      `api/kyc/update/${_user.userid}`,
      userInfo,
    );
    if (resp) {
      setMessage({ message: "Saved" });
      setToastOpen(true);
    }
    setDisabled(false);
  };

  const reSumbitKYC = async () => {
    setDisabled(true);
    let resp = await GlobalService.httpPost(
      `api/kyc/submitKYC/${_user.userid}`,
      userInfo,
    );
    if (resp) {
      setMessage({ message: "Re-submitted KYC" });
      setToastOpen(true);
    }
    setDisabled(false);
  };

  const [showRejectOptions, setShowRejectOptions] = useState(false);
  const rejectKYC = async () => {
    setDisabled(true);
    console.log(userInfo)
    labelsMapping.forEach((step) => {
      let status = updatedKYCStatus[step.statusField]
      if(!status) {
        alert(`status not selected for ${step.statusField}`)
        return;
      }
      if(status != 'INCOMPLETE') {
        updatedKYCStatus[step.remarksField] = null
      } else {
        if(!updatedKYCStatus[step.remarksField]) {
          alert(`${step.statusField} needs remarks since its open`)
          return;
        }
      }
    })
    console.log('updatedKYCStatus', updatedKYCStatus)
    let resp = await GlobalService.httpPost(
      `api/kyc/rejectKYC/${_user.userid}`,
      {userInfo, updatedKYCStatus},
    );
    if (resp) {
      setMessage({ message: "KYC rejected for user to re-verify" });
      setToastOpen(true);
    }
    setDisabled(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value, event.target.name);
    let newUser = { ...userInfo };
    newUser[event.target.name] = event.target.value;
    setUserInfo(newUser);
  };

  const handleCloseToast = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const copyProfileFields = () => {
    console.log(_user.accountFields);
    navigator.clipboard.writeText(_user.accountFields);
    alert("Copied");
  };

  const getStateLabel = (state) => {
    let restrictedStates = ['CT', "HI", 'NY', 'NH', 'TX', 'VT', 'VA']
    if(restrictedStates.includes(state))
      return 'State (Restricted)'
    return 'State'
  }
  
  return (
    <Container sx={{ marginBottom: "20px", width: "100%" }}>
      <Snackbar
        open={toastOpen}
        autoHideDuration={5000}
        onClose={handleCloseToast}
      >
        <Paper sx={{ minWidth: "200px" }}>
          <Alert severity={"info"} sx={{ width: "100%" }}>
            {message.message}
          </Alert>
        </Paper>
      </Snackbar>
      <Card sx={{ width: "100%", padding: "10px" }}>
        {_user && (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="h5">#{_user.userid}</Typography>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: "10px 0", width: "50%" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  required
                  name="name"
                  label="Full name"
                  value={userInfo.name}
                  onChange={handleChange}
                  size="small"
                />
                <TextField
                  required
                  disabled
                  label="Email"
                  value={_user.user.email}
                  size="small"
                />
                <TextField
                  required
                  name="phoneNumber"
                  label="Phone number"
                  value={userInfo.phoneNumber}
                  onChange={handleChange}
                  size="small"
                />
                <TextField
                  required
                  name="dateOfBirth"
                  label="Date of Birth"
                  value={userInfo.dateOfBirth}
                  onChange={handleChange}
                  size="small"
                />
                <TextField
                  required
                  name="street1"
                  label="Street 1"
                  value={userInfo.street1}
                  onChange={handleChange}
                  size="small"
                />
                <TextField
                  required
                  name="street2"
                  label="Street 2"
                  value={userInfo.street2}
                  onChange={handleChange}
                  size="small"
                />
              </Box>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: "10px 0", width: "25%" },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <TextField
                    required
                    name="city"
                    label="City"
                    value={userInfo.city}
                    onChange={handleChange}
                    size="small"
                  />
                  <TextField
                    required
                    name="state"
                    label="State"
                    value={userInfo.state}
                    onChange={handleChange}
                    size="small"
                  />
                  <TextField
                    required
                    name="country"
                    label="Country"
                    value={userInfo.country}
                    onChange={handleChange}
                    size="small"
                  />
                  <TextField
                    required
                    name="postalCode"
                    label="Postal"
                    value={userInfo.postalCode}
                    onChange={handleChange}
                    size="small"
                  />
                </div>
              </Box>
              <Box sx={{ padding: "10px 0" }}>
                <Button
                  variant="contained"
                  onClick={saveUserInfo}
                  sx={{ marginRight: "10px" }}
                  disabled={disabled}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={copyProfileFields}
                  sx={{ marginRight: "10px" }}
                  disabled={disabled}
                >
                  Copy Wyre Fields
                </Button>
                <Button
                  variant="contained"
                  onClick={reSumbitKYC}
                  sx={{ marginRight: "10px" }}
                  color="success"
                  disabled={disabled}
                >
                  Re-submit
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setShowRejectOptions(!showRejectOptions)}
                  sx={{ marginRight: "10px" }}
                  color="error"
                  disabled={disabled}
                >
                  Review Reject
                </Button>
              </Box>
              {showRejectOptions && (
                <Box sx={{ padding: "10px", border: "1px solid black" }}>
                  {Object.keys(updatedKYCStatus).length > 0 &&
                    labelsMapping.map((ele, index) => (
                      <>
                        <Typography sx={{ marginBottom: "5px" }}>
                          {ele.statusField}
                        </Typography>
                        <RejectOptions
                          defaultRemarks={defaultRemarks}
                          setUpdateKYCStatus={setUpdateKYCStatus}
                          updatedKYCStatus={updatedKYCStatus}
                          _user={_user}
                          ele={ele}
                        />
                        {index !== labelsMapping.length - 1 && (
                          <Divider
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        )}
                      </>
                    ))}

                  <Button
                    variant="contained"
                    onClick={rejectKYC}
                    sx={{ marginTop: "20px" }}
                    color="error"
                    disabled={disabled}
                  >
                    Confirm Reject
                  </Button>
                </Box>
              )}

              <Box sx={{ padding: "10px 0" }}>
                <Chip
                  label={`${_user.accountId} - ${_user.accountStatus}`}
                  variant="outlined"
                />
                <Chip
                  label={`${_user.paymentMethodId} - ${_user.paymentMethodStatus}`}
                  variant="outlined"
                />
              </Box>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Wyre Errors</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {computeWyreErrors(_user.accountFields)}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    What user sees? -{" "}
                    {_user.user.user_kyc_status[0].overallStatus}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {computeRemarks(_user.user.user_kyc_status[0])}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={4}>
              <img src={_user.user.imagefront} width="100%" />
              <img src={_user.user.imageback} width="100%" />
            </Grid>
          </Grid>
        )}
        {/* <Typography variant="h5">Name</Typography>
          <Typography variant="subtitle1">Email</Typography>
          <Typography variant="body1">Phone</Typography>
          <Typography variant="body1">Date of Birth</Typography>
          <Typography variant="body1">Address</Typography>
          <Typography variant="body1">SSN: Redacted</Typography> */}
      </Card>
      {disabled && <LinearProgress />}
    </Container>
  );
}

export default User;