import React, { useEffect, useState } from 'react';
import global from '../services/global';
import {
    Container,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Skeleton,
    Box,
    styled,
    IconButton,
    Collapse,
    Tooltip,
} from "@mui/material";
import { Email, Schedule, Message, ExpandMore, ExpandLess } from '@mui/icons-material';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxWidth: 1000,
    margin: '2rem auto',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));

const StyledHeadCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));

const MessageCell = styled(TableCell)(({ theme }) => ({
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}));


function truncateMessage(message, maxLength = 50) {
    if (message.length <= maxLength) return message;
    return message.substr(0, maxLength - 3) + '...';
}


export default function Appointments() {
    const [loading, setLoading] = useState(true);
    const [appointments, setAppointments] = useState<{email:string, id: number, message: string, createdAt: Date}[]>([]);
    const [expandedRows, setExpandedRows] = useState({});

    useEffect(() => {
        (async () => {
            const response = await global.httpGet('api/admin/appointments');
            setAppointments(response.appointments);
            setLoading(false);
        })();
    }, []);

    const toggleRowExpansion = (id) => {
        setExpandedRows(prev => ({ ...prev, [id]: !prev[id] }));
    };

    function getRows() {
        return appointments.map((appointment) => (
            <React.Fragment key={appointment.id}>
                <StyledTableRow>
                    <TableCell>
                        <Box display="flex" alignItems="center">
                            <Email color="action" sx={{ marginRight: 1 }} />
                            {appointment.email}
                        </Box>
                    </TableCell>
                    <MessageCell>
                        <Tooltip title={appointment.message} arrow>
                            <Box display="flex" alignItems="center">
                                <Message color="action" sx={{ marginRight: 1, flexShrink: 0 }} />
                                <span>{truncateMessage(appointment.message)}</span>
                            </Box>
                        </Tooltip>
                    </MessageCell>
                    <TableCell>
                        <Box display="flex" alignItems="center">
                            <Schedule color="action" sx={{ marginRight: 1 }} />
                            {new Date(appointment.createdAt).toLocaleString("en-US", { timeZone: "America/New_York" })}
                        </Box>
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={() => toggleRowExpansion(appointment.id)}>
                            {expandedRows[appointment.id] ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </TableCell>
                </StyledTableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                        <Collapse in={expandedRows[appointment.id]} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Full Message
                                </Typography>
                                <Typography>{appointment.message}</Typography>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        ));
    }

    return (
        <Container>
            <Typography variant="h4" align="center" gutterBottom sx={{ marginTop: 4 }}>
                Appointments
            </Typography>
            <Paper>
                <StyledTableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="appointments table">
                        <StyledTableHead>
                            <TableRow>
                                <StyledHeadCell>Email</StyledHeadCell>
                                <StyledHeadCell>Message</StyledHeadCell>
                                <StyledHeadCell>Created At</StyledHeadCell>
                                <StyledHeadCell>Actions</StyledHeadCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Skeleton animation="wave" height={53} />
                                        <Skeleton animation="wave" height={53} />
                                        <Skeleton animation="wave" height={53} />
                                    </TableCell>
                                </TableRow>
                            ) : !appointments.length ? (
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Typography align="center">No Appointments Found</Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                getRows()
                            )}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </Paper>
        </Container>
    );
}