import * as React from "react";
import { hot } from "react-hot-loader";
import { ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter,
  Routes,
  useLocation,
  Navigate,
  Route,
  Link,
} from "react-router-dom";
import { Toolbar, Grid, Button, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Typography, Tooltip } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuAppBar from './NavBar';
import Login from '../pages/Login';
import KYC from '../pages/KYC/KYC';
import theme from '../assets/theme';
import Home from "../pages/Home";

const reactLogo = require("./../assets/img/react_logo.svg");
import "./../assets/scss/App.scss";
import GlobalService from "../services/global";
import Users from "../pages/User/";
import TransactionView from "../pages/Transaction/TransactionView";
import StripeTx from "../pages/StripeTx";
import Questions from "../pages/Questions";
import SendEmail from "../pages/SendEmail";
import Referrals from "../pages/Referrals";
import Products from "../pages/Products";
import Waitlist from '../pages/Waitlist';
import Appointments from '../pages/Appointments';

function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();

  if (!GlobalService.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <LoggedInPage>{children}</LoggedInPage>;
}

function drawer() {
	let isTestFlight = localStorage.getItem('isTestFlight') == "true";
  return (
    <div>
		<Tooltip title="Logout to switch ENV">
			<Toolbar style={{backgroundColor: isTestFlight?"#FDB51C":"#F44336"}}  >
				<Typography style={{color: isTestFlight?"black":"white"}} >{isTestFlight?"Testflight":"Production"} ENV on</Typography>
			</Toolbar>
		</Tooltip>
      <Divider />
      <List>
        <Link to="/">
          <ListItem button key={"Dashboard"}>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
        </Link>
        <Link to="/kyc">
          <ListItem button key={"KYC"}>
            <ListItemText primary={"KYC"} />
          </ListItem>
        </Link>
        <Link to="/users">
          <ListItem button key={"Users"}>
            <ListItemText primary={"Users"} />
          </ListItem>
        </Link>
        <Link to="/transactions">
          <ListItem button key={"Transaction"}>
            <ListItemText primary={"Transaction"} />
          </ListItem>
        </Link>
        <Link to="/products">
          <ListItem button key={"Products"}>
            <ListItemText primary={"Products"} />
          </ListItem>
        </Link>
        <Link to="/stripe-tx">
          <ListItem button key={"Stripe Tx"}>
            <ListItemText primary={"Stripe Tx"} />
          </ListItem>
        </Link>
        <Link to="/questions">
          <ListItem button key={"Questions"}>
            <ListItemText primary={"Questions"} />
          </ListItem>
        </Link>
        <Link to="/referrals">
          <ListItem button key={"Referrals"}>
            <ListItemText primary={"Referrals"} />
          </ListItem>
        </Link>
        <Link to="/waitlist">
          <ListItem button key={"Waitlist"}>
            <ListItemText primary={"Waitlist"} />
          </ListItem>
        </Link>
        <Link to="/appointments">
          <ListItem button key={"Appointments"}>
            <ListItemText primary={"Appointments"} />
          </ListItem>
        </Link>
      </List>
      {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );
}

function LoggedInPage({ children }: { children: JSX.Element }) {
  let drawerWidth = 250;

  return (
    <>
      <Drawer
        variant="permanent"
        anchor={"left"}
        open={true}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: "primary.main",
            boxSizing: "border-box",
          },
        }}
        // onClose={toggleDrawer(anchor, false)}
      >
        {drawer()}
      </Drawer>
      <Grid
        container
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
        }}
      >
        <MenuAppBar />
        {children}
      </Grid>
    </>
  );
}

function App() {
  GlobalService.init();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="*" element={() => <Navigate to="/" />}></Route>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/kyc"
            element={
              <RequireAuth>
                <KYC />
              </RequireAuth>
            }
          />
          <Route
            path="/users"
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />
          <Route
            path="/transactions"
            element={
              <RequireAuth>
                <TransactionView />
              </RequireAuth>
            }
          />
          <Route
            path="/stripe-tx"
            element={
              <RequireAuth>
                <StripeTx />
              </RequireAuth>
            }
          />
          <Route
            path="/questions"
            element={
              <RequireAuth>
                <Questions />
              </RequireAuth>
            }
          />
          <Route path="/login" element={
            <>
              <MenuAppBar />
              <Login />
            </>
          } />
          <Route
            path="/referrals"
            element={
              <RequireAuth>
                <Referrals />
              </RequireAuth>
            }
          />
          <Route
            path="/products"
            element={
              <RequireAuth>
                <Products />
              </RequireAuth>
            }
          />
          <Route
            path="/waitlist"
            element={
              <RequireAuth>
                <Waitlist />
              </RequireAuth>
            }
          />
          <Route
            path="/appointments"
            element={
              <RequireAuth>
                <Appointments />
              </RequireAuth>
            }
          />

        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
