import React, { useEffect, useState } from 'react';
import global from '../services/global';
import {
    Container,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Skeleton,
    Box,
    styled,
} from "@mui/material";
import { Email, Schedule, FormatListNumbered } from '@mui/icons-material';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxWidth: 900,
    margin: '2rem auto',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));

const StyledHeadCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));

interface WaitListedUser {
    email: string;
    createdAt: string;
}

export default function Waitlist() {
    const [loading, setLoading] = useState(true);
    const [waitListedUsers, setWaitListedUsers] = useState<WaitListedUser[]>([]);

    useEffect(() => {
        (async () => {
            const response = await global.httpGet('api/admin/waitlist');
            setWaitListedUsers(response.waitlist);
            setLoading(false);
        })();
    }, []);

    function getRows() {
        return waitListedUsers.map((user, index) => (
            <StyledTableRow key={index}>
                <TableCell>
                    <Box display="flex" alignItems="center">
                        <FormatListNumbered color="action" sx={{ marginRight: 1 }} />
                        {index + 1}
                    </Box>
                </TableCell>
                <TableCell>
                    <Box display="flex" alignItems="center">
                        <Email color="action" sx={{ marginRight: 1 }} />
                        {user.email}
                    </Box>
                </TableCell>
                <TableCell>
                    <Box display="flex" alignItems="center">
                        <Schedule color="action" sx={{ marginRight: 1 }} />
                        {user.createdAt
                            ? new Date(user.createdAt).toLocaleString("en-US", { timeZone: "America/New_York" })
                            : "N/A"}
                    </Box>
                </TableCell>
            </StyledTableRow>
        ));
    }

    return (
        <Container>
            <Typography variant="h4" align="center" gutterBottom sx={{ marginTop: 4 }}>
                Waitlist Users
            </Typography>
            <Paper>
                <StyledTableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="waitlist users table">
                        <StyledTableHead>
                            <TableRow>
                                <StyledHeadCell>Index</StyledHeadCell>
                                <StyledHeadCell>Email</StyledHeadCell>
                                <StyledHeadCell>Created At</StyledHeadCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <Skeleton animation="wave" height={53} />
                                        <Skeleton animation="wave" height={53} />
                                        <Skeleton animation="wave" height={53} />
                                    </TableCell>
                                </TableRow>
                            ) : !waitListedUsers.length ? (
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <Typography align="center">No Waitlist Users Found</Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                getRows()
                            )}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </Paper>
        </Container>
    );
}