import {
	Container,
	Typography,
	TextField,
	Button,
	FormGroup,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Skeleton,
	Grow,
	Tabs,
	Tab,
  } from "@mui/material";
  import { LoadingButton, TabPanel } from "@mui/lab";
  import * as React from "react";
  
  import "./../../assets/scss/App.scss";
  import GlobalService from "../../services/global";
import QuestionsTab from "./QuestionsTab";
  
  function Questions() {
	const [value, setValue] = React.useState(0);

  
	return (
	  <Container sx={{ width: "100%", paddingBottom: "30px" }}>
		<Tabs
		  value={value}
		  aria-label="basic tabs example"
		  onChange={(e, val) => setValue(val)}
		  style={{ marginTop: "10px" }}
		>
		  <Tab label="Not Answered" />
		  <Tab label="All" />
		</Tabs>
		<QuestionsTab mode="NOT_ANSWERED" tabValue={value} />
		<QuestionsTab mode="ALL" tabValue={value} />
	  </Container>
	);
  }
  
  export default Questions;
  