import { Box, Card, CardContent, Divider, Grid, Skeleton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';

export default function ProductStats({ product, loading }) {

    if (loading) {
        return (
            <Grid item xs={4}>
                <Box>
                    <Card variant="outlined">
                        <CardContent sx={{p:1}}>
                            <Skeleton variant="text" width="60%" height={40} />
                            <Skeleton variant="text" width="40%" height={30} sx={{ mb: 1.5 }} />
                            {[...Array(4)].map((_, index) => (
                                <Box key={index}>
                                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                        <Skeleton variant="rectangular" width="100%" height={20} />
                                    </Box>
                                    {index < 3 && <Divider sx={{ my: 1 }} />}
                                </Box>
                            ))}
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        );
    }

    let productName = product.find(p => p[0] === 'product_code')[1].toLowerCase().replaceAll("_", " ");
    productName = productName[0].toUpperCase() + productName.slice(1);
    product = product.filter(p => p[0] !== 'product_code');

    return (
        <Grid item xs={4}>
            <Box>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {productName}
                        </Typography>
                        <Typography sx={{ mb: 1.5, mt: 1 }} color="text.secondary">
                            Statistics
                        </Typography>
                        <Table size="small">
                            <TableBody>
                                {product.map((row) => (
                                    <TableRow
                                        key={row[0]}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row[0][0].toUpperCase() + row[0].slice(1).replaceAll("_", " ")}: {row[1]}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Box>
        </Grid>
    );
}
